<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="10"
      )
        v-row.mb-6(
          align="center"
          justify="space-between"
          v-if="hasLoaded"
        )
          v-col(
            cols="12"
            md="auto"
          )
            v-layout(
              align-center
            )
              h1.text-h4
                span(v-if="user.isAdmin") {{ order.shop.name }}
                span(v-else) {{ $day(order.createdAt).format('M/D/YY h:mm A') }}
              v-col.d-flex
                .actions.ml-auto
                  v-chip.ml-2(
                    color="primary"
                  ) {{ order.status }}
            p(v-if="user.isAdmin") {{ $day(order.createdAt).format('M/D/YY h:mm A') }}
              //- v-tooltip(
              //-   top
              //- )
              //-   template(#activator="{ on, attrs}")
              //-     v-btn.px-0.ml-5(
              //-       v-on="on"
              //-       min-width="40px"
              //-       @click="printOrder"
              //-       color="primary"
              //-     )
              //-       v-icon mdi-printer
              //-   span Print Order
          v-col(
            cols="12"
            md="auto"
          )
            v-btn(
              v-if="user.isRegionAdmin || user.isShopAdmin"
              @click="deleteOrder"
              color="error"
            )
              v-icon(left) mdi-delete
              | Delete Order
            //- v-menu(
            //-   offset-y
            //-   v-if="user.isAdmin"
            //- )
            //-   template(#activator="{on, attrs }")
            //-     v-btn(
            //-       color="primary"
            //-       v-on="on"
            //-     )
            //-       | Change Status
            //-       v-icon(right) mdi-chevron-down
            //-   v-list
            //-     v-list-item(
            //-       @click="changeStatus('PROCESSED')"
            //-     )
            //-       v-list-item-title Processed
        v-card(
          outlined
          v-if="hasLoaded"
        )
          v-card-text
            v-row

          v-data-table.clickable(
            :headers="headers"
            no-data-text="No Products Found"
            disable-pagination
            :items="rows"
            @click:row=""
            hide-default-footer
            :items-per-page="-1"
          )
            template(#item.partNumber="{ item: row }")
              .pn
                h4 {{ row.partNumber }}
                | &nbsp;QTY {{ getPart(row.partNumber) ? getPart(row.partNumber).qty : '' }}
            template(#item.item="{ item: row }")
              v-layout(
                flex
                align-center
              )
                v-img.shrink.mr-2(
                  height="40px"
                  width="40px"
                  :src="`/parts/${row.partNumber}.jpg`"
                )
                h4 {{ getPart(row.partNumber) ? getPart(row.partNumber).desc : '' }}
          h4.pl-4 {{ listTotalQuantity }}
            span:p.ml-2 Total Items
        v-card.mt-5(
          outlined
          v-if="hasLoaded"
        )
          v-card-title
            h4.pl-4 Additional Notes
            v-card-subtitle
              p {{order.comment}}
        v-layout.mt-5(
          justify-center
        )
          v-btn.mr-2(
            color="secondary"
            @click="readdToCart"
          )
            v-icon(left) mdi-cart
            | Re-add to Cart
          DownloadOrderButton(
            v-if="user.isAdmin"
            :order="order"
            :text="'Download To Excel'"
          )
</template>

<script>
import { useGet, useFind } from 'feathers-vuex'
import { computed } from '@vue/composition-api'
import DownloadOrderButton from '@/components/DownloadOrderButton'

import parts from '@/data/parts.json'

const accumulator = (accumulator, currentValue) => accumulator + currentValue

export default {
  name: 'Order',
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  components: {
    DownloadOrderButton
  },
  setup (props, context) {
    const { Order } = context.root.$FeathersVuex.api

    const params = computed(() => {
      return { query: { $eager: 'shop' } }
    })

    const { item: order, hasLoaded } = useGet({
      params,
      model: Order,
      id: props.orderId
    })

    const { OrderRow } = context.root.$FeathersVuex.api

    const rowsParams = computed(() => {
      const query = {
        query: {
          order_id: props.orderId,
          $limit: 99
        }
      }

      return query
    })

    const { items: rows, isPending: areRowsPending } = useFind({
      model: OrderRow,
      params: rowsParams
    })

    return {
      rows,
      areRowsPending,
      order,
      hasLoaded
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    parts () {
      return this.data.parts
    },
    listTotalQuantity () {
      if (!this.rows.length) {
        return 0
      }
      const listQuantity = this.rows.map(row => row.qty)
      const listTotalQuantity = listQuantity.reduce(accumulator)

      return listTotalQuantity
    }
  },
  methods: {
    getPart (partNumber) {
      return this.parts.filter((part) => part.pn === partNumber)[0]
    },
    async deleteOrder () {
      try {
        const message = 'Are you sure you want to delete this order?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        const currentOrder = this.order

        if (isYes) {
          await this.order.remove()
          this.$snackSuccess('Order Deleted')
          this.$router.push({
            name: 'orders',
            params: { shopId: currentOrder.shop_id }
          })
        }
      } catch (error) {
        this.$logError(error)
      }
    },
    async changeStatus (status) {
      try {
        await this.order.patch({ data: { status } })
        this.$snackSuccess('Status Updated')
      } catch (error) {
        this.$snackError(error.message)
      }
    },
    readdToCart () {
      this.rows.forEach((row) => {
        const { OrderRow } = this.$FeathersVuex.api
        // eslint-disable-next-line no-unused-vars
        const newRow = new OrderRow({
          qty: row.qty,
          partNumber: row.partNumber
        })
      })

      this.$router.push({ name: 'cart' })
    }
  },
  data: () => ({
    data: parts,
    headers: [
      {
        text: 'Qty.',
        value: 'qty'
      },
      {
        text: 'Item',
        value: 'item'
      },
      {
        text: 'Part #',
        value: 'partNumber'
      }
    ]
  })
}
</script>
